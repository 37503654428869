<template>
  <div v-if="!isCatch">
    <div ref="chartRef" id="chartRef" style="height: 693px;width: 100%;"></div>
  </div>
  <div v-else style="height: 693px;width: 100%;">
    <a-alert message="图表加载失败，请检查代码。" banner />
    <div style="margin-top: 20px;">{{ catchText }}</div>
  </div>
</template>

<script>
import $ from "jquery"

export default {
  name: "codeChart",
  props: {
    myCode: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      isCatch: false,
      catchText: '',
      externalScripts: 'https://echarts.baidu.com/resource/echarts-gl-latest/dist/echarts-gl.min.js'
    }
  },
  created() {
    this.$nextTick(()=>{
      this.getEcharts()
    })
  },
  methods: {
    getEcharts() {
      try {
        // const optionCode = this.myCode.replace(RegExp('/asset/get/s', "g"), 'https://static.muzimuzi.com/asset/get/s')
        const optionCode = this.myCode.replace(RegExp('/asset/get/s', "g"), 'https://www.muzimuzi.com/alioss/asset/get/s')
        const myChart = this.$echarts.init(this.$refs.chartRef)
        const getOption = new Function(
            '$',
            'echarts',
            'myChart',
            'try{' + optionCode + '\n' + 'if (option) {return option}' + '}catch(e){return null}'
        )
        const newOption = getOption($, this.$echarts, myChart)
        if (newOption && !optionCode.includes('myChart.setOption')) {
          myChart.setOption(newOption)
        }
      }catch (e) {
          this.isCatch = true
          this.catchText = e
        }
    }
  }
}
</script>

<style scoped>

</style>
