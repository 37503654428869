import request from '../plugins/request'

// 查询图表列表
export function getChartSearch(params) {
    return request({
        url: '/chart/search',
        method: 'get',
        params: params
    })
}

// 查询图表代码信息
export function getChartDetail(params) {
    return request({
        url: '/chart/detail',
        method: 'get',
        params: params
    })
}

// 查询图表类型
export function getChartTypes(params) {
    return request({
        url: '/chart/types',
        method: 'get',
        params: params
    })
}
