// 新增用户图表收藏
import request from "../plugins/request";

export function createUserChart(data) {
    return request({
        url: '/user/chart/create',
        method: 'post',
        data
    })
}

// 删除用户图表收藏
export function deleteUserChart(data) {
    return request({
        url: '/user/chart/delete',
        method: 'post',
        data
    })
}

// 获取用户图表收藏状态
export function userChartStatus(params) {
    return request({
        url: '/user/chart/status',
        method: 'get',
        params: params
    })
}

// 获取用户图表收藏列表
export function userChartSearch(params) {
    return request({
        url: '/user/chart/search',
        method: 'get',
        params: params
    })
}

// 获取用户图表收藏类别
export function userChartTypes(params) {
    return request({
        url: '/user/chart/types',
        method: 'get',
        params: params
    })
}
