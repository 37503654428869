<template>
  <a-layout>
    <Header />
    <a-layout-content :style="{ padding: '16px', marginTop: '64px' }">
      <a-spin :spinning="!loading" tip="数据加载中...">
        <a-row :gutter="16">
          <a-col :span="10">
            <a-card :title="data.title" size="small">
              <template #extra>
                <a-button type="primary" size="small" @click="refreshChart">运行</a-button>
              </template>
              <MonacoEditor
                  v-if="loading"
                  height="691"
                  language="javascript"
                  theme="vs"
                  :code="data.code"
                  :options="options"
                  @mounted="onMounted"
                  @codeChange="onCodeChange"
              >
              </MonacoEditor>
            </a-card>
          </a-col>
          <a-col :span="14">
            <a-card size="small">
              <template #title>
                <span>ECharts版本号：{{ data.echartsVersion }}</span>
              </template>
              <template #extra>
                <span v-if="!isLogin">登录后可收藏</span>
                <a-button v-if="isLogin && !isCollect" :loading="collectLoading" size="small" shape="round" @click="handleCreateUserChart">收藏</a-button>
                <a-button v-if="isLogin && isCollect" :loading="collectLoading" type="primary" shape="round" size="small" @click="handleDeleteUserChart">已收藏</a-button>
              </template>
              <codeChart v-if="loading" :my-code="data.code" />
            </a-card>
          </a-col>
        </a-row>
      </a-spin>
    </a-layout-content>
    <Footer />
  </a-layout>
</template>
<script>
import { getChartDetail } from "../../api/chart"
import { userChartStatus, createUserChart, deleteUserChart } from "../../api/userChart"
import { isLogin } from "../../utils/auth"
import Header from '../../components/header'
import Footer from '../../components/footer'
import codeChart from '../../components/codeChart'
import MonacoEditor from 'vue-monaco-editor'

export default {
  components: { Header, Footer, codeChart, MonacoEditor },
  data() {
    return {
      isLogin: false,
      loading: false,
      data: {
        chartId: 0,
        chartSysNo: '',
        createdAt: '',
        title: '',
        thumbnailUrl: '',
        viewCount: '',
        externalScripts: undefined,
        echartsVersion: undefined,
        code: ''
      },
      isCollect: false,
      collectLoading: false,
      options: {
        automaticLayout: true
      }
    }
  },
  created() {
    this.getChartDetail()
    this.isLogin = !!isLogin();
    this.userChartStatus()
  },
  methods: {
    // 获取代码信息
    getChartDetail() {
      getChartDetail({no: this.$route.params.no}).then(res =>{
        this.data = res.data
        this.loading = true
      })
    },
    onMounted(editor) {
      this.monacoEditor = editor;
    },
    onCodeChange() {
      this.data.code = this.monacoEditor.getValue()
    },
    // 获取用户收藏信息
    userChartStatus() {
      if (isLogin()) {
        userChartStatus({chartSysNo: this.$route.params.no}).then(res =>{
          if (res.data === null) {
            this.isCollect = false
          }else {
            this.isCollect = true
          }
        })
      }
    },
    // 添加收藏
    handleCreateUserChart() {
      this.collectLoading = true
      createUserChart({chartId: this.data.chartId}).then(res => {
        console.log(res.data)
        this.userChartStatus()
        this.collectLoading = false
      })
    },
    // 删除收藏
    handleDeleteUserChart() {
      this.collectLoading = true
      deleteUserChart({chartId: this.data.chartId}).then(res => {
        console.log(res.data)
        this.userChartStatus()
        this.collectLoading = false
      })
    },
    // 提交新代码
    refreshChart() {
      this.loading = false
      this.$nextTick(() => {
        this.loading = true
      })
    }
  }
}
</script>
<style scoped>
</style>
